<template>
<div>
   <form  class="">
      <div class="row">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <div class="row g-3 align-items-end">        
                     <div class="col-12" >
                        <h5 class="mb-2">PAYMENT INFORMATION</h5>
                     </div>
                     <!-- <div class="col-12">
                        <div class="bd-callout my-0 bd-callout-info">
                           ${{$filters.money_format(editItem.approved_amount)}} has been approved for lease
                        </div>
                     </div> -->
                     <div class="col-lg-8">
                        <label class="form-label" for="leased_amount">Your lease amount (max: ${{ $filters.money_format(editItem.approved_amount) }})</label>
                        <div class="input-group">
                           <span class="input-group-text">$</span>
                           <input @input="calculateInitalAmount()" @blur="calculateEstimate()" v-model="data.leased_amount" 
                           id="leased_amount" class="form-control" type="number"
                           placeholder="Lease amount" :class="{ 'is-invalid': v$.data.leased_amount.$error }" >
                           <span class="input-group-text">.00</span>
                        </div>
                        <div v-for="(item, index) in v$.data.leased_amount.$errors"
                           :key="index" class="text-danger">
                           <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <label class="form-label" for="funding_option">Funding Option</label>
                        <select @change="calculateEstimate();calculateInitalAmount()" v-model="data.funding_option" name="funding_option"
                           class="form-select">
                           <option value="" disabled selected>--Select term--</option>
                           <option  v-for="(ing, fOIndex) in fundingOptions"
                              :value="ing.value" :key="fOIndex" > {{ing.label}} </option>
                        </select>
                        <div v-for="(item, index) in v$.data.funding_option.$errors"
                           :key="index" class="text-danger">
                           <span v-if="item.$message">{{ item.$message }}</span>
                        </div> 
                     </div>
                     <div class="col-md-6 col-lg-5">
                        <label class="form-label ">Lease Term</label>
                        <select @change="calculateEstimate()" v-model="data.contract_term" name="contract_term"
                        class="form-select">
                           <option value="" disabled selected>--Select term--</option>
                           <option  v-for="(period, tIndex) in leaseTerms"
                              :value="period.value" :key="tIndex" > {{period.label}} </option>
                        </select>
                     </div>
                     <div class="col-md-6 col-lg-3">
                        <label for="pricing_factor">
                           Pricing Factor <span class="text-danger small">*</span>
                        </label>
                        <input type="number" v-model="data.pricing_factor"
                           :class="{ 'is-invalid': v$.data.pricing_factor.$error }"
                           id="pricing_factor" name="pricing_factor" class="form-control"
                           @blur="calculateEstimate()" placeholder="Enter pricing factor" />
                        <div v-for="(item, index) in v$.data.pricing_factor.$errors" :key="index"
                           class="invalid-feedback">
                           <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <label for="initial_payment">
                           Down Payment (min: 10%) <span class="text-danger small">*</span>
                        </label>
                        <input type="number" v-model="data.initial_payment"
                           :class="{ 'is-invalid': v$.data.initial_payment.$error }"
                           id="initial_payment" name="initial_payment" class="form-control"
                           @blur="calculateEstimate()" placeholder="Enter Down Payment" />
                        <div v-for="(item, index) in v$.data.initial_payment.$errors" :key="index"
                           class="invalid-feedback">
                           <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                     </div>
                     <div class="col-12">
                        <div class="mb-2">
                           <label class="d-block">How would you like to pay?</label>
                           <span v-if="data.payment_method">Payment method has been added <a @click.prevent="data.payment_method = ''" href="#">Click to change</a></span>
                           <button v-else @click.prevent="addPaymentMethod()" class="btn btn-primary" type="button"> Add Payment Method</button>
                        </div>
                        <small>
                           Note: Please inform the customer that all recurring payments for "Debit Card" will be made with 2% Merchant Fee.
                        </small>
                     </div>
                  </div>
               </div>
            </div>
            <div v-if="enableDeviceData" class="card">
               <div class="card-body">
                  <div class="row g-3 align-items-end">        
                     <div class="col-12" >
                        <h5 class="mb-2">CHOOSE YOUR DEVICE</h5>
                     </div>
                     <div v-if="data.funding_option == 'buy'" class="col-sm-6">
                        <label class="form-label ">Device Condition</label>
                        <select v-model="data.contract_item.device_condition" name="contract_item.device_condition"
                        class="form-select">
                           <option value="" disabled selected>--Select condition--</option>
                           <option value="new"> New </option>
                           <option value="used"> Used </option>
                           <option value="refurbished"> Refurbished </option>
                        </select>
                     </div>
                     <div class="col-sm-6">
                        <label class="form-label mb-1">Device <span class="text-danger">*</span></label>
                        <select  @change="categoryChange()" class="form-select" v-model="data.contract_item.category_id"
                        name="type" placeholder="--select make--">
                              <option disabled value="">--select device--</option>
                              <option v-for="(category, caIndex) in categories" :key="caIndex"
                              :value="category.id">{{category.title}}</option>
                        </select>
                     </div>
                     <div class="col-sm-6">
                        <label class="form-label mb-1">Make <span class="text-danger">*</span></label>
                        <select :disabled="fetchingCategory"  @change="brandChange()" class="form-select" v-model="data.contract_item.brand_id"
                        name="type" placeholder="--select make--">
                              <option disabled value="">--select make--</option>
                              <option v-for="(brand, brIndex) in brands()" :key="brIndex"
                              :value="brand.id">{{brand.title}}</option>
                        </select>
                     </div>
                     <div class="col-sm-6 flex-grow-1">
                        <label class="form-label mb-1">Model <span class="text-danger">*</span></label>
                        <select @change="deviceChange()" class="form-select" v-model="data.contract_item.device_id"
                           name="type" placeholder="--select make--">
                              <option disabled value="">--select make--</option>
                              <option v-for="(device, deIndex) in devices()" :key="deIndex"
                              :value="device.id">{{device.model}}</option>
                        </select>
                     </div>
                     <div class="col-sm-6">
                        <label class="form-label mb-1">IMEI / Serial number <span class="text-danger">*</span></label>
                        <div class="input-group">
                              <select @change="requiredKeyChange()" style="max-width:90px;"
                                 v-model="data.contract_item.required_key" class="form-select text-start input-group-text">
                                 <option selected value="imei">IMEI</option>
                                 <option value="serial">SERIAL</option>
                              </select>
                              <input v-if="data.contract_item.required_key == 'imei'" v-model="data.contract_item.imei_number"
                                 class="form-control" type="number" placeholder="IMEI number">
                              <input v-if="data.contract_item.required_key == 'serial'" v-model="data.contract_item.serial_number"
                                 class="form-control" type="text" placeholder="Serial number">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="card loading-viewport">
               <is-loading v-if="isLoading" :box="true" />
               <div class="card-body">
                  <div class="row g-3">        
                     <div class="col-12" >
                        <h5 class="mb-2">PAYMENT SCHEDULE</h5>
                     </div>
                     <div class="col-12">
                        <label class="form-label ">How often are you paid?</label>
                        <div class="">
                           <span v-for="(frequency, fIndex) in scheduleFrequencies" :key="fIndex"
                              class="d-grid d-sm-inline me-2">
                              <input v-model="data.schedule.frequency" type="radio" class="btn-check" name="schedule_frequency"
                              :id="`schedule_frequency_${frequency.value}`" autocomplete="off" :value="frequency.value">
                              <label class="btn btn-outline-primary" :for="`schedule_frequency_${frequency.value}`">{{frequency.label}}</label>
                           </span>
                        </div>
                        <div class="mt-3" v-if="enableDaysOfWeek">
                           <label class="form-label">What day of the week are you normally paid?</label>
                           <div class="">
                              <span v-for="(day, dIndex) in daysOfWeek" :key="dIndex"
                                 class="d-grid d-sm-inline me-2">
                                 <input v-model="data.schedule.day_of_week" type="radio" class="btn-check" name="schedule_day_of_week"
                                 :id="`schedule_day_of_week_${day.value}`" autocomplete="off" :value="day.value">
                                 <label class="btn btn-outline-primary" :for="`schedule_day_of_week_${day.value}`">{{day.label}}</label>
                              </span>
                           </div>
                        </div>
                        <div class="row mt-3" v-if="data.schedule.frequency == 'twice_a_month'">
                           <div class="col-md-9">
                              <label class="form-label">What days of the month are you normally paid? Cannot be fewer than 11 days apart?</label>
                              <div class="input-group">
                                 <select v-model="data.schedule.first_day_of_month" class="form-select">
                                    <option disabled value=""> select day 1 </option>
                                    <option v-for="day in days(20)" :key="day.value" :value="day.value">
                                       {{ day.label }}
                                    </option>
                                 </select>
                                 <select v-model="data.schedule.second_day_of_month"
                                    class="form-select">
                                    <option disabled value=""> select day 2 </option>
                                    <option  v-for="day in scheduleSecondDays" :key="day" :value="day.value">
                                       {{ day.label }}
                                    </option>
                                 </select>
                              </div> 
                           </div>
                        </div>
                        <div class="row mt-3" v-if="data.schedule.frequency == 'monthly'">
                           <div class="col-md-6">
                              <label class="form-label">What day of the month are you normally paid?</label>
                              <select v-model="data.schedule.first_day_of_month" class="form-select">
                                 <option disabled value=""> day of month  </option>
                                 <option v-for="day in days()" :key="day.value" :value="day.value">
                                    {{ day.label }}
                                 </option>
                              </select>
                           </div>
                        </div>
                        <div class="row mt-3" v-if="enableWeekdayOfMonth">
                           <div class="col-md-6">
                              <label class="form-label">What week of the month are you normally paid?</label>
                              <select v-model="data.schedule.week_of_month" class="form-select">
                                 <option disabled value=""> week of month  </option>
                                 <option v-for="week in weeksOfMonth" :key="week.value" :value="week.value">
                                    {{ week.label }}
                                 </option>
                              </select>
                           </div>
                        </div>
                        <div class="row mt-3" v-if="enableRelativeWeek">
                           <div class="col-md-6">
                              <label class="form-label">Was your normal payday last week or this week?</label>
                              <div>
                                 <span class="d-grid d-sm-inline me-2">
                                    <input v-model="data.schedule.relative_week" type="radio" class="btn-check" name="schedule_relative_week"
                                    id="schedule_relative_week_previous" autocomplete="off" value="previous">
                                    <label class="btn btn-outline-primary" for="schedule_relative_week_previous">Last Week ( {{lastWeekFromDay}} ) </label>
                                 </span>
                                 <span class="d-grid d-sm-inline me-2">
                                    <input v-model="data.schedule.relative_week" type="radio" class="btn-check" name="schedule_relative_week"
                                    id="schedule_relative_week_current" autocomplete="off" value="current">
                                    <label class="btn btn-outline-primary" for="schedule_relative_week_current">This Week ( {{thisWeekFromDay}} ) </label>
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12">
                        <div class="row">
                           <div class="col-lg-6">
                              You are typically paid: <br> <strong>{{data.schedule.description || '...'}}</strong>
                           </div>
                           <div class="col-lg-6">
                              Approximate next three paydays: <br> 
                              <strong>{{ data.schedule.next_three_days.length ?
                                 formatThreeDates(data.schedule.next_three_days) : '...' }}</strong>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="row">
         <div class="col-lg-6">
            <div class="card loading-viewport">
               <is-loading v-if="isLoading" :box="true" />
               <div class="card-body">
                  <div class="" >
                     <h5 class="mb-4">SUMMARY</h5>
                  </div>
                  <div class="d-flex mb-2 align-items-center justify-content-between">
                     <span>Moriso Cash Price</span> <strong>{{numberFormat(estimate.moriso_cash_price)}}</strong>
                  </div>
                  <div v-if="data.contract_term" class="d-flex mb-2 align-items-center justify-content-between">
                     <span>{{data.contract_term}}-Month Total of Payments</span> <strong>{{numberFormat(estimate.total_amount)}}</strong>
                  </div>
                  <div class="d-flex mb-2  align-items-center justify-content-between">
                     <span class="me-2"> Renewal Payment Amount</span>  <strong>{{ numberFormat(estimate.renewal_amount) }}</strong>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                     <span>Number of Payments</span> <strong>{{estimate.number_of_renewals || '...'}}</strong>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-lg-6">
            <div class="card loading-viewport">
               <is-loading v-if="isLoading" :box="true" />
               <div class="card-body">
                  <div class="" >
                     <h5 class="mb-4">TODAY'S PAYMENT</h5>
                  </div>
                  <div class="d-flex mb-4 align-items-center justify-content-between">
                     <span>Today's Down payment</span> <strong> ${{ $filters.money_format(estimate.initial_payment)}}</strong>
                  </div>
                  <div class="">
                     <b-button size="lg" variant="primary"  class="me-3"
                     @click.prevent="update()"
                     type="button">Checkout Now</b-button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </form>
   <b-modal v-model="popupPaymentMethod" hide-footer centered
      title="Connect payment method" :no-close-on-backdrop="true"
      title-class="fs-6" @hide="popupPaymentMethod = false">
      <stripe-payment-form :config="paymentConfig"
         btn-text="Authenticate" v-if="popupPaymentMethod"
        @setupConfirmed="setPaymentMethod($event)" />
    </b-modal>
</div>
</template>

<script>
import { required, numeric, minValue, maxValue, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import StripePaymentForm from '@/components/forms/StripePaymentForm.vue';
import IsLoading from '@/components/IsLoading.vue';

export default {
   components: { StripePaymentForm, IsLoading},
   setup() {
      return { v$: useVuelidate() };
   },
   props:{
      editItem:{
         type: Object,
         default: null
      },
    },
    data(){
        return{
            isLoading: false,
            data: {
               leased_amount: null,
               funding_option: "",
               contract_term: "",
               payment_method: "",
               initial_payment: "",
               pricing_factor: 2.10,
               schedule: {
                  frequency: "",
                  day_of_week: "",
                  first_day_of_month:"",
                  second_day_of_month:"",
                  week_of_month: "",
                  relative_week: "",
                  description: "",
                  next_three_days: []
               },
               contract_item:{
                  device_id: null,
                  imei_number: "",
                  serial_number: "",
                  required_key: "imei",
                  device_condition: ""
               }
            },
            estimate: {
               moriso_cash_price: "",
               number_of_renewals: "",
               renewal_amount: 0,
               initial_payment: 0,
               total_amount: 0
            },
            popupPaymentMethod: false,
            fetchingCategory: false,

            scheduleFrequencies:[
               { value: 'weekly', label: 'Weekly'},
               { value: 'every_other_week', label: 'Every Other Week' },
               { value: 'twice_a_month', label: 'Twice a Month' },
               { value: 'monthly', label: 'Day of the Month' },
               { value: 'weekday_of_month', label: 'Weekday of a Month' },
            ],
            daysOfWeek:[
               { value: '0', label: 'Sunday' },
               { value: '1', label: 'Monday' },
               { value: '2', label: 'Tuesday' },
               { value: '3', label: 'Wednesday' },
               { value: '4', label: 'Thursday' },
               { value: '5', label: 'Friday' },
               { value: '6', label: 'Saturday' },
            ],
            weeksOfMonth:[
               { value: '1', label: '1st' },
               { value: '2', label: '2nd' },
               { value: '3', label: '3rd' },
               { value: '4', label: '4th' },
            ],
            leaseTerms:[
               { value: '3', label: '3 months'},
               { value: '4', label: '4 months'},
               { value: '5', label: '5 months'},
               { value: '6', label: '6 months'},
               { value: '7', label: '7 months'},
               { value: '8', label: '8 months'},
               { value: '9', label: '9 months'},
               { value: '10', label: '10 months'},
               { value: '11', label: '11 months'},
               { value: '12', label: '12 months'},
            ],
            fundingOptions:[
               { value: 'cash', label: 'Cash'},
               { value: 'repair', label: 'Repair' },
               { value: 'buy', label: 'Buy' },
            ],
            paymentConfig: null,
        }
    },
    watch:{
      "data.schedule.frequency":  function (_){
         this.data.schedule.day_of_week =
         this.data.schedule.first_day_of_month =
         this.data.schedule.second_day_of_month =
         this.data.schedule.week_of_month =
         this.data.schedule.relative_week = ""
      },
      "data.schedule.day_of_week":  function (_){
         this.validateSchedule()
      },
      "data.schedule.first_day_of_month":  function (_){
         this.data.schedule.second_day_of_month = ""
         this.validateSchedule()
      },
      "data.schedule.second_day_of_month":  function (_){
         this.validateSchedule()
      },
      "data.schedule.week_of_month":  function (_){
         this.validateSchedule()
      },
      "data.schedule.relative_week":  function (_){
         this.validateSchedule()
      }
    },
    validations() {
      return {
         data:{
            leased_amount:  {
               required, numeric,
               minValue: minValue(100),
               maxValue: maxValue(this.editItem.approved_amount)
            },
            pricing_factor: { 
               required,
               minValue: minValue(1.5),
               maxValue: maxValue(5)
            },
            initial_payment: {
               required,
               maxValue: maxValue(parseFloat(this.data.leased_amount))
            },
            funding_option: { required },
            contract_term: { required },
         }
      }
    },
    computed:{
      days() {
         return (daysLength = 31) => {
            return Array.from({ length: daysLength }, (_, index) => {
            const dayValue = index + 1;
            return {
               value: dayValue,
               label: dayValue.toString().padStart(2, '0')
            };
            });
         };
      },
      scheduleSecondDays(){
         const startingDay = parseInt(this.data.schedule.first_day_of_month);
         if (!startingDay || startingDay >= 21) return [];
         const length = startingDay <= 11 ? 10 : 21 - startingDay;
         return Array.from({ length }, (_, index) => {
            const dayValue = startingDay + index + 11;
            return {
               value: dayValue,
               label: dayValue.toString().padStart(2, '0')
            };
         });
      },
      enableDaysOfWeek(){
         return ['weekly','every_other_week','weekday_of_month'].includes(this.data.schedule.frequency)
      },
      enableWeekdayOfMonth(){
         return this.data.schedule.frequency == 'weekday_of_month' && this.data.schedule.day_of_week
      },
      enableRelativeWeek(){
         return this.data.schedule.frequency == 'every_other_week' && this.data.schedule.day_of_week
      },
      lastWeekFromDay(){
         if(!this.data.schedule.day_of_week) return ''
         return this.$filters.monthDayWithSuffix(this.$filters.getLTDatesForWeekday(this.data.schedule.day_of_week)[0])
      },
      thisWeekFromDay(){
         if(!this.data.schedule.day_of_week) return ''
         return this.$filters.monthDayWithSuffix(this.$filters.getLTDatesForWeekday(this.data.schedule.day_of_week)[1])
      },
      formatThreeDates(){
         return (dates) => dates.map(day => this.$filters.monthDayWithSuffix(day)).join(', ')
      },
      numberFormat(){
         return (value) => value ? `$${this.$filters.money_format(value)}` : '...'
      },
      enableDeviceData(){
         return ['repair','buy'].includes(this.data.funding_option)
      },
      categories(){
         return this.$store.state.categories
      },
      brands(){
         return (_) => {
            const brands = []
            const item = this.data.contract_item;
            if (item.category_id && item.category){
               brands.push(...item.category.brands)
            }
            return brands;
         }
      },
      devices(){
         return (_) => {
            const devices = [];
            const item = this.data.contract_item;
            if (item.category_id && item.brand_id && item.category){
                  devices.push(...item.category.devices.
                  filter(i => i.brand_id === item.brand_id))
            }
            return devices;
         }
      },
   },
   methods:{
      update() {
         this.v$.$validate().then(result =>{
            if (!result) {this.alertError('Form is not valid'); return;}
            if(!this.data.payment_method){this.alertError('Payment method is required'); return;}
            if(!this.data.schedule.next_three_days.length){this.alertError('Your payment frequency is invalid'); return;}
            
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/contracts/${this.editItem.id}/checkout`, formData)
            .then((response) => {
               this.$store.dispatch("changeLoaderValue", false)
               if(response.data.success){
                  this.resetForm();
                  this.$router.push({path: `/leases/${response.data.data.id}`})
               }
               if(response.status === 404){
                  this.$router.push({path: `/leases/${this.editItem.id}`})
               }
            })
         })
      },
      validateSchedule(){
         this.data.schedule.description = "",
         this.data.schedule.next_three_days = []
         
         if(this.data.schedule.frequency == "weekly" && this.data.schedule.day_of_week){
            this.getSchedules()

         }else if(this.data.schedule.frequency == "every_other_week"
            && this.data.schedule.day_of_week && this.data.schedule.relative_week){
            this.getSchedules()

         }else if(this.data.schedule.frequency == "twice_a_month" 
            && this.data.schedule.first_day_of_month && this.data.schedule.second_day_of_month){
            this.getSchedules()

         }else if(this.data.schedule.frequency == "monthly" && this.data.schedule.first_day_of_month){
            this.getSchedules()

         }else if(this.data.schedule.frequency == "weekday_of_month"
            && this.data.schedule.day_of_week && this.data.schedule.week_of_month){
            this.getSchedules()
         }

      },
      getSchedules(){
         const formData = new FormData()
         this.isLoading = true
         formData.append('data', JSON.stringify(this.data.schedule))
         this.$http.post('/contracts/schedules', formData)
         .then((response) => {
            this.isLoading = false
            if(response.data.success){
               Object.assign(this.data.schedule, response.data.data)
               this.calculateEstimate()
            }
         })
      },
      calculateInitalAmount(){
         this.data.initial_payment = 0.1 * this.data.leased_amount
      },
      calculateEstimate(){
         if(!this.data.leased_amount || !this.data.schedule.frequency
          || !this.data.contract_term || !this.data.funding_option
          || !this.data.pricing_factor ){
            return;
         }
         this.v$.$validate().then(result =>{
            if (!result) {this.alertError('Form is not valid'); return;}
            
            const formData = new FormData()
            this.isLoading = true
            formData.append('data', JSON.stringify({
               store_id: this.editItem.store_id,
               pricing_factor: this.data.pricing_factor,
               initial_payment: this.data.initial_payment,
               leased_amount: this.data.leased_amount,
               renewal_frequency: this.data.schedule.frequency,
               funding_option: this.data.funding_option,
               contract_term: this.data.contract_term
            }))
            this.$http.post('/contracts/estimate', formData)
            .then((response) => {
               this.isLoading = false
               if(response.data.success){
                  Object.assign(this.estimate, response.data.data)
               }
            })
         });         
      },
      addPaymentMethod(){
         this.loadStripe()
         this.$store.dispatch("changeLoaderValue", true)
         this.$http.get(this.absoluteUrl('/api/stripe/loan-setup'))
            .then(response => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
               this.paymentConfig = response.data.data.config
               this.popupPaymentMethod = true
            }
         })
      },
      setPaymentMethod(data){
         if(data.status === 'succeeded'){
            this.data.payment_method = data.payment_method
         }
         this.popupPaymentMethod = false
      },
      categoryChange(){
         let item = {
            category_id: this.data.contract_item.category_id,
            device_id: null,
            imei_number: "",
            serial_number: "",
            category: ""
         }
         this.fetchingCategory = true
         this.$http.get(this.absoluteUrl(`/api/categories/${item.category_id}`))
            .then((response) => {
               this.fetchingCategory = false
               if(response.data.success){
                  item.category = response.data.data;
                  Object.assign(this.data.contract_item, item)
               }
         })
      },
      brandChange(){
         let item = {
            device_id: null,
            imei_number: "",
            serial_number: "",
         }
         Object.assign(this.data.contract_item, item)
      },
      deviceChange(){
         let item = {
            imei_number: "",
            serial_number: "",
         }
         Object.assign(this.data.contract_item, item)
      },
      requiredKeyChange(itemIndex){
         let item = {
            imei_number: "",
            serial_number: "",
         }
         Object.assign(this.data.contract_item, item)
      },
      resetForm(){
         this.v$.$reset()
      }
    },
}
</script>