
<template>
<div class="pb-4">
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Lease checkout</h5>
      <div v-if="contract && contract.status == 'draft'">
        <lease-checkout-form :editItem="contract" />
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import LeaseCheckoutForm from "@/components/forms/LeaseCheckoutForm.vue"

export default {
  components: { 
    IsLoading,
    LeaseCheckoutForm,
  },
  data(){
    return {
      isLoading: true,
      contract: null
    }
  },
  watch: {
    '$route.params.leaseId': function (id){
      if(id) this.fetchContract()
    },
  },
  methods:{
    fetchContract(){
      this.isLoading = true
      this.$http.get(`/contracts/${this.$route.params.leaseId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.contract = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    }
  },
  created(){
    this.fetchContract()
    this.$store.dispatch('fetchCategories')
  }
}

</script>

<style lang="scss">

</style>
